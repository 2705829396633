import React from 'react';
import { Helmet } from 'react-helmet';
import '../scss/main.scss';

export default () => (
    <div className="vct-main">
        <Helmet>
            <html className="no-js" lang="en" />
            <title>Page Not Found</title>
        </Helmet>
        
        <div className="container">
            <div className="row">
                <div className="col-12 jumbotron">
                <h1>Page Not Found</h1>
                <p>Sorry, but the page you were trying to view does not exist.</p>
                </div>
            </div>
        </div>
    </div>
)
